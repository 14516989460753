<template>
  <div class="config-item">
    <div class="info">
      <div class="label">
        <img v-if="data.prefixIcon" :src="data.prefixIcon" alt="">
        {{ onLang(data.title) }}
      </div>
      <img v-if="selectedIcon" :src="selectedIcon" alt="">
      <div v-else class="value">{{ onLang(selectedLabel) }} {{ data.unit ? onLang(data.unit) : '' }}</div>
    </div>
    <div class="tip">
      <TooltipElement v-if="showTooltip" :content="onLang(data.tooltip)" alwaysShow placement="bottom">
        <img src="@/assets/icons/Info_24.svg" alt="">
      </TooltipElement>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigItem',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    data: Object,
    value: {
      type: [ Number, String ],
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    selectedLabel() {
      if (this.data.options)
        return this.data.options.find(item => item.value === this.value).label
      else {
        let uiVal = this.value
        if (this.data.getUiValue) {
          uiVal = this.data.getUiValue(this.value)
        }
        return this.data.label ? this.data.label[uiVal] : uiVal
      }
        
    },
    selectedIcon() {
      if (this.data.options){
        const selectedOption = this.data.options.find(item => item.value === this.value)
        if(selectedOption.icon === undefined)
          return null
        return require(`@/assets/icons/${selectedOption.icon}`)
      }
      else return null
    },
  },
  methods: {
    onLang(str) {
      if (str && String(str).includes('$t_')) {
        return this.$t(str.replace('$t_', ''))
      } else {
        return str
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.config-item {
  display: flex;
  align-items: center;
  column-gap: 12px;
  .info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .tip {
    width: 20px;
    height: 20px;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      @include filter_FFF_50;
    }
  }
  .label {
    display: flex;
    align-items: center;
    font-size: px2rem(16);
    color: #ffffff;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .value {
    font-size: px2rem(14);
    color: #ffffff;
  }
  .unit {
    color: #ffffff80;
  }
}
</style>